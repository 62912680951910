import React, { ChangeEvent, FC, useCallback } from "react";
import Input from "components/Input/Input";
import { Col, Container } from "react-grid-system";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import {
  authCreditCardFeePercent,
  authSelectLocale,
} from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import { BooksAddFundsFormProps } from "types/books";
import { toastResponseError } from "utils/responseMessageHelper";
import formatter from "../../../../utils/formatter";
import {
  AddFundsContainer,
  AddFundsFormRow,
  BooksAddFundsFormWrapper,
  SummaryCurrency,
  SummaryPriceItem,
  SummaryPriceWrapper,
} from "../../styles/BooksAddFunds.style";

//constants for the range of funds value
const MIN_AMOUNT_ADD_FUNDS = 0.01;
const MAX_AMOUNT_ADD_FUNDS = 10000.0;

const BooksAddFundsForm: FC<BooksAddFundsFormProps> = React.memo(
  ({
    values,
    handleChange,
    errors: { amount: amountError, address = {}, cardDetails = {} },
    touched: touchedProp,
    handleBlur,
    setFieldValue,
    currentLocale,
    authCreditCardFeePercent,
  }) => {
    const { t } = useTranslation("common");
    const handleCountryChange = useCallback(
      (value: string) => setFieldValue("address.country", value),
      [setFieldValue],
    );

    const touched = {
      address: {},
      cardDetails: {},
      ...touchedProp,
    };

    const amountFloat = parseFloat(values.amount);
    const commissionFeeFloat = parseFloat(authCreditCardFeePercent);
    const commissionFeeValue = formatter.roundTo2Decimal(
      commissionFeeFloat * amountFloat,
    );
    // Label in format 5% to show
    const commissionFeeValueLabel = commissionFeeFloat * 100;

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value.replace(/[^0-9.]/g, "");
      let numericValue = parseFloat(inputValue);
      setFieldValue("amount", inputValue);

      //range of funds value
      if (
        numericValue > MAX_AMOUNT_ADD_FUNDS ||
        numericValue < MIN_AMOUNT_ADD_FUNDS
      ) {
        toastResponseError(
          t("books.errorAmountRangeAddFunds", {
            min: MIN_AMOUNT_ADD_FUNDS,
            max: MAX_AMOUNT_ADD_FUNDS,
          }),
          "error-range-funds",
        );
      }
    };

    const renderPriceItem = (title: string, amount: number) => {
      return (
        <SummaryPriceItem>
          {title}
          <SummaryPriceWrapper>
            <SummaryCurrency>{"$"}</SummaryCurrency>
            {Math.abs(amount)}
          </SummaryPriceWrapper>
        </SummaryPriceItem>
      );
    };

    return (
      <BooksAddFundsFormWrapper>
        <AddFundsContainer fluid>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                name="amount"
                value={values.amount}
                onChange={handleAmountChange}
                invalid={touched.amount && !!amountError}
                placeholder={"$ 0.00"}
                tooltip={amountError}
                type="number"
              />
              {amountFloat > 0 &&
                renderPriceItem(
                  t("parcels.commissionFeeLabel", {
                    fee: commissionFeeValueLabel,
                  }),
                  commissionFeeValue,
                )}
            </Col>
          </AddFundsFormRow>
        </AddFundsContainer>
        <Container fluid>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                placeholder={t("purchase.creditCard.name")}
                name="cardDetails.name"
                value={values.cardDetails.name}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.name}
                invalid={touched.cardDetails.name && !!cardDetails.name}
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={12}>
              <InputMask
                placeholder={t("purchase.creditCard.number")}
                name="cardDetails.number"
                value={values.cardDetails.number}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.number}
                invalid={touched.cardDetails.number && !!cardDetails.number}
                mask={"9999 9999 9999 9999"}
              >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            <Col xs={6}>
              <InputMask
                placeholder={t("purchase.creditCard.expiration")}
                name="cardDetails.expiration"
                value={values.cardDetails.expiration}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.expiration}
                invalid={
                  touched.cardDetails.expiration && !!cardDetails.expiration
                }
                mask={"99/9999"}
              >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Col>
            <Col xs={6}>
              <Input
                placeholder={t("purchase.creditCard.cvv")}
                name="cardDetails.cvv"
                value={values.cardDetails.cvv}
                onChange={handleChange}
                onBlur={handleBlur}
                tooltip={cardDetails.cvv}
                invalid={touched.cardDetails.cvv && !!cardDetails.cvv}
              />
            </Col>
          </AddFundsFormRow>
        </Container>
      </BooksAddFundsFormWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    currentLocale: authSelectLocale,
    authCreditCardFeePercent,
  }),
);

export default withConnect(BooksAddFundsForm);
