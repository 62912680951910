import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { BooksRedeemVoucherFormValues } from "types/books";
import * as yup from "yup";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../utils/responseMessageHelper";

const redeemVoucherFormik = (
  t: TFunction,
  booksRedeemVoucher: any,
  getProfile: any,
) => {
  return {
    initialValues: {
      code: "",
    },

    validationSchema: yup.object({
      code: yup.string().required(t("error.requiredField")),
    }),
    onSubmit: (
      values: BooksRedeemVoucherFormValues,
      { setSubmitting, resetForm }: FormikHelpers<BooksRedeemVoucherFormValues>,
    ) => {
      const { code } = values;

      setSubmitting(true);

      booksRedeemVoucher({ code })
        .then(() => {
          resetForm();
          toastResponseSuccess(
            t("books.voucher.successfulRedeemTransactions", { code }),
          );
          setSubmitting(false);
          getProfile();
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
  };
};

export default redeemVoucherFormik;
