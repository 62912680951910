import { initialState } from '../reducers/books';
import { peek, selector } from '../selectorHelpers';

export const selectBooks = (state) => state.get('books', initialState);

export const booksTransactionsSelectHistoryItems = selector(
  selectBooks,
  peek('historyItems'),
);

export const booksTransactionsSelectIsLoading = selector(
  selectBooks,
  peek('isLoading'),
);

export const booksTransactionsSelectParams = selector(
  selectBooks,
  peek('params'),
);

export const booksTransactionsSelectTotalAmount = selector(
  selectBooks,
  peek('totalAmount'),
);

export const selectedRecipientId = selector(
  selectBooks,
  peek('selectedRecipientId'),
);

export const amountFunds = selector(selectBooks, peek('amountFunds'));

export const voucherHistoryItems = selector(
  selectBooks,
  peek('voucherHistory'),
);

export const booksSelectDepositWallets = selector(
  selectBooks,
  peek('depositWallets'),
);

export const booksSelectDepositsHistory = selector(
  selectBooks,
  peek('depositsHistory'),
);
