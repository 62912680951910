import React, { FC } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { redirect } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { RechargePayPalCheckoutDto } from "types/api/recharge";
import { ButtonColor, ButtonVariant } from "../../../../enums";
import { booksCheckoutPayPal } from "../../../../reduxFolder/reducers/books";
import { authPaypalFeePercent } from "../../../../reduxFolder/selectors/globalSelectors";
import formatter from "../../../../utils/formatter";
import addPayPalFundsFormik from "../../helpers/addPayPalFundsFormik";
import {
  ContentContainer,
  FormWrapper,
  PayButton,
} from "../../styles/BooksAddFunds.style";
import BooksAddPayPalFundsForm from "./BooksAddPayPalFundsForm";

interface BooksAddFundsPanelProps {
  booksCheckoutPayPal: ({ data }: RechargePayPalCheckoutDto) => Promise<void>;
  authPaypalFeePercent: string;
}

const BooksAddFundsPanel: FC<BooksAddFundsPanelProps> = React.memo(
  ({ booksCheckoutPayPal, authPaypalFeePercent }) => {
    const { t } = useTranslation("common");
    const redirectFunc = () => redirect("/parcels");

    const {
      handleBlur,
      handleSubmit,
      handleChange,
      errors,
      values,
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
    } = useFormik(addPayPalFundsFormik(t, booksCheckoutPayPal, redirectFunc));

    const { amount } = values;
    const amountFloat = parseFloat(amount);
    const commissionFeeFloat = parseFloat(authPaypalFeePercent);
    const commissionFeeValue =
      amountFloat > 0
        ? formatter.roundTo2Decimal(commissionFeeFloat * amountFloat)
        : 0;
    const payBtnTitle = `${t("common.processPaypal")} ($${amountFloat + commissionFeeValue})`;

    return (
      <ContentWrapper>
        <Heading>{t("books.addFundsPanel.chargePaypal")}</Heading>
        <ContentContainer>
          <FormWrapper>
            <BooksAddPayPalFundsForm
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              booksCheckoutPayPal={booksCheckoutPayPal}
            />
          </FormWrapper>
          <PayButton
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
            variant={ButtonVariant.Filled}
            color={ButtonColor.Secondary}
          >
            {payBtnTitle}
          </PayButton>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    authPaypalFeePercent,
  }),
  {
    booksCheckoutPayPal,
  },
);

export default withConnect(BooksAddFundsPanel);
