import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

export const initialState = fromJS({
  isOpen: false,
  panelType: null,
  panelData: null,
});

// actions
const OPEN_PANEL = 'rightPanel/OPEN_PANEL';
const CLOSE_PANEL = 'rightPanel/CLOSE_PANEL';
const UPDATE_IN_PANEL_DATA = 'rightPanel/UPDATE_IN_PANEL_DATA';

// actions creators
export const openRightPanel = (panelType, panelData) => ({
  type: OPEN_PANEL,
  isOpen: true,
  panelType,
  panelData,
});

export const closeRightPanel = () => ({
  type: CLOSE_PANEL,
});

export const updateInPanelData = (panelData) => ({
  type: UPDATE_IN_PANEL_DATA,
  panelData,
});

// reducer
const rightPanelReducer = handleActions(
  {
    [OPEN_PANEL]: (state, { panelType, panelData }) =>
      state
        .set('isOpen', true)
        .set('panelType', panelType)
        .set('panelData', panelData),
    [CLOSE_PANEL]: (state) => state.set('isOpen', false).set('panelType', null),
    [UPDATE_IN_PANEL_DATA]: (state, { panelData }) => {
      return state.update('panelData', (oldPanelData) => {
        return oldPanelData.merge(panelData);
      });
    },
  },
  initialState,
);

export default rightPanelReducer;
