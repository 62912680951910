import React, { ChangeEvent, FC, useState } from "react";
import Input from "components/Input/Input";
import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  authSelectLocale,
  authSelectUser,
} from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import { BooksVoucherCreateFormProps, VoucherHistoryItem } from "types/books";
import { toastResponseError } from "utils/responseMessageHelper";
import CopyToClipboard from "../../../../components/CopyToClipboard/CopyToClipboard";
import { ButtonColor, ButtonVariant } from "../../../../enums";
import { getProfile } from "../../../../reduxFolder/reducers/auth";
import { booksCreateVoucher } from "../../../../reduxFolder/reducers/books";
import { Currency } from "../../../../styles/parcelsStyles";
import createBalanceVoucherFormik from "../../helpers/createBalanceVoucherFormik";
import {
  AddFundsContainer,
  AddFundsFormRow,
  Balance,
  BalanceText,
  BooksVoucherCreateFormWrapper,
  PayButton,
  VoucherResponseCodeText,
  VoucherResponseCodeWrapper,
} from "../../styles/BooksAddFunds.style";

//constants for the range of funds value
const MIN_AMOUNT_ADD_FUNDS = 0.01;
const MAX_AMOUNT_ADD_FUNDS = 10000.0;

const BooksVoucherCreateForm: FC<BooksVoucherCreateFormProps> = React.memo(
  ({ booksCreateVoucher, user, getProfile }) => {
    const { t } = useTranslation("common");
    const [voucherResponse, setVoucherResponse] =
      useState<null | VoucherHistoryItem>(null);

    const redirectFunc = (resp: any) => {
      if (resp && resp.value && resp.value.get("data")) {
        setVoucherResponse(resp.value.get("data").toJS());
      }
      getProfile();
    };

    const {
      handleBlur,
      handleSubmit,
      handleChange,
      values,
      errors: { value: amountError },
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
    } = useFormik(
      createBalanceVoucherFormik(t, booksCreateVoucher, redirectFunc),
    );

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value.replace(/[^0-9.]/g, "");
      let numericValue = parseFloat(inputValue);
      setFieldValue("value", inputValue);

      //range of funds value
      if (
        numericValue > MAX_AMOUNT_ADD_FUNDS ||
        numericValue < MIN_AMOUNT_ADD_FUNDS
      ) {
        toastResponseError(
          t("books.errorAmountRangeAddFunds", {
            min: MIN_AMOUNT_ADD_FUNDS,
            max: MAX_AMOUNT_ADD_FUNDS,
          }),
          "error-range-funds",
        );
      }
    };

    const renderBalance = () => (
      <AddFundsFormRow>
        <Balance>
          {t("account.balance")}:
          <BalanceText>
            <Currency>$</Currency> {user.get("balance")}
          </BalanceText>
        </Balance>
      </AddFundsFormRow>
    );

    const payBtnTitle = `${t("books.voucher.createVoucher")} ($${values.value})`;

    return (
      <BooksVoucherCreateFormWrapper>
        <AddFundsContainer fluid>
          {renderBalance()}
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                name="value"
                value={values.value}
                onChange={handleAmountChange}
                invalid={touched.value && !!amountError}
                placeholder={"Enter voucher amount $ 0.00"}
                tooltip={amountError}
                type="number"
              />
            </Col>
          </AddFundsFormRow>
          <AddFundsFormRow>
            {voucherResponse && (
              <VoucherResponseCodeWrapper>
                <CopyToClipboard text={voucherResponse.code}>
                  <VoucherResponseCodeText>
                    {voucherResponse.code}
                  </VoucherResponseCodeText>
                </CopyToClipboard>
              </VoucherResponseCodeWrapper>
            )}
          </AddFundsFormRow>
        </AddFundsContainer>
        {voucherResponse && (
          <PayButton variant={ButtonVariant.Filled} color={ButtonColor.Primary}>
            <CopyToClipboard text={voucherResponse.code}>
              {`${voucherResponse.code} (${voucherResponse.value}$)`}
            </CopyToClipboard>
          </PayButton>
        )}
        {!voucherResponse && (
          <PayButton
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
            variant={ButtonVariant.Filled}
            color={ButtonColor.Secondary}
          >
            {payBtnTitle}
          </PayButton>
        )}
      </BooksVoucherCreateFormWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    user: authSelectUser,
    currentLocale: authSelectLocale,
  }),
  {
    booksCreateVoucher,
    getProfile,
  },
);

export default withConnect(BooksVoucherCreateForm);
