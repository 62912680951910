import {
  AuthChangePasswordDto,
  AuthChangePasswordResponseDto,
  AuthPasswordResetDto,
  AuthPasswordResetResponseDto,
  AuthSignInDto,
  AuthSignUpDto,
  AuthSignUpResponseDto,
  AuthUpdateProfileDto,
  AuthUserDto,
} from "types/api/auth";
import { BooksTransactionsHistoryParamsDto } from "types/api/books";
import {
  AddInventoryParcelItemsDto,
  AddonsMethodsResponseDto,
  AddParcelsDto,
  CartResponseDto,
  ConsolidationAddonsMethodsResponseDto,
  CustomInformationDto,
  DeleteCartResponseDto,
  DeliveryShippingMethodsResponseDto,
  PackingMethodsResponseDto,
  SetAddonsMethodDto,
  SetAddressDto,
  SetConsolidationAddonsMethodsDto,
  SetDeliveryMethodDto,
  SetPackingMethodDto,
  SetPartnerIdDto,
  SetPartnerIdResponseDto,
} from "types/api/cart";
import { ImportCustomsDeclarationDto } from "types/api/common";
import { DashboardResponseDto, KnowledgeDto } from "types/api/dashboard";
import { DestinationAddressDto } from "types/api/destination_addresses";
import {
  CreateCustomsDeclarationsForExpectedItemDto,
  CreateExpectedShipmentDto,
  CustomsDeclarationsForExpectedItemResponseDto,
  ExpectedShipmentsDto,
  UpdateExpectedShipmentDto,
} from "types/api/expected";
import {
  HistoryDetailedParamsDto,
  HistoryFullDetailedItemResponseDto,
  HistoryParamsDto,
  HistoryResponseDto,
} from "types/api/history";
import { ItemsPutAwayDto, ItemsReceivedItemDto } from "types/api/items";
import { OutgoingDto, OutgoingResponseDto } from "types/api/outgoing";
import {
  PurchaseAllConformationsResponseDto,
  PurchaseConsolidationCreditCardCompleteDto,
  PurchaseConsolidationPaypalsCompleteDto,
} from "types/api/purchase_all";
import {
  RechargeCreditCardDto,
  RechargePayPalCheckoutDto,
  RechargePayPalCompleteDto,
} from "types/api/recharge";
import {
  CreateServiceRequestDto,
  ServiceRequestsResponseDto,
} from "types/api/service_requests";
import {
  CreateShipmentsCustomDeclarationDto,
  ShipmentsUpdateCommentDto,
  ShopsResponseDto,
  StorageDto,
  StorageResponseDto,
  UpdateAddonsDto,
} from "types/api/shipments";
import {
  BooksCreateBalanceVoucherFormValues,
  BooksCreateCryptoDepositFormValues,
  BooksRedeemVoucherFormValues,
  BooksUpdateCryptoDepositFormValues,
} from "../types/books";
import client from "./client";

const API = {
  auth: {
    signIn: (data: AuthSignInDto): Promise<AuthSignUpResponseDto> =>
      client.post("/auth/sign_in", data),
    logOut: () => client.get("/auth/sign_out"),
    signUp: (data: AuthSignUpDto): Promise<AuthSignUpResponseDto> =>
      client.post("/auth", data),
    getProfile: (): Promise<AuthUserDto> => client.get("/user"),
    requestPasswordReset: (
      data: AuthPasswordResetDto,
    ): Promise<AuthPasswordResetResponseDto> =>
      client.post("/auth/password", data),
    changePassword: (
      data: AuthChangePasswordDto,
    ): Promise<AuthChangePasswordResponseDto> =>
      client.patch("/auth/password", data),
    updateProfile: (
      data: AuthUpdateProfileDto,
    ): Promise<AuthSignUpResponseDto> => client.patch("/auth", data),
  },

  dashboard: {
    getDashboard: (): Promise<DashboardResponseDto> => client.get("/dashboard"),
    getFAQ: ({ currentLocale }: KnowledgeDto): Promise<unknown> =>
      client.get(
        `/kb/${currentLocale.toLowerCase()}/faq?locale=${currentLocale.toLowerCase()}`,
      ),
    getKnowledgeBaseHelpers: ({
      currentLocale,
    }: KnowledgeDto): Promise<unknown> =>
      client.get(
        `/kb/${currentLocale.toLowerCase()}/helpers?locale=${currentLocale.toLowerCase()}`,
      ),
  },
  destinations: {
    createAddress: (data: any) => client.post("/destination_addresses", data),
    getAddresses: (params: DestinationAddressDto) =>
      client.get("/destination_addresses", { params }),
    updateAddress: (id: number, data: any) =>
      client.patch(`/destination_addresses/${id}`, data),
    deleteAddress: ({ id }: { id: number }) =>
      client.delete(`/destination_addresses/${id}`),
  },
  serviceRequests: {
    getRequestTypes: (): Promise<ServiceRequestsResponseDto[]> =>
      client.get("/service_request_types"),
    getShipmentRequestType: (shipment_id: number) =>
      client.get(`/shipments/${shipment_id}/service_requests`),
    createServiceRequest: ({ itemId, ...data }: CreateServiceRequestDto) =>
      client.post(`/shipments/${itemId}/service_requests`, data),
    cancelServiceRequest: ({
      itemId,
      requestId,
    }: {
      itemId: number;
      requestId: number;
    }) =>
      client.put(
        `/shipments/${itemId}/service_requests/${requestId}/cancel_service_request`,
      ),
    cancelRequestInventoryServiceRequest: ({ taskId }: { taskId: number }) =>
      client.post(`/tasks/${taskId}/cancel`),
    createRequestInventoryShipment: ({ id }: { id: number }) =>
      client.post(`/items/${id}/create_unpack_task`),
    createRequestTransferShipments: ({
      ids,
      partnerTransferId,
    }: {
      ids: number[];
      partnerTransferId: string;
    }) =>
      client.post(`/shipments/request_transfer`, {
        ids,
        ffid: partnerTransferId,
      }),
    completeRequestTransferShipments: ({ codes }: { codes: string[] }) =>
      client.post(`/shipments/complete_transfer`, {
        codes,
      }),
    createRequestTransferInventoryItem: ({
      sku,
      partnerTransferId,
    }: {
      sku: string[];
      partnerTransferId: string;
    }) =>
      client.post(`/items/request_transfer`, {
        sku,
        sbid: partnerTransferId,
      }),
    completeRequestTransferInventoryItems: ({ codes }: { codes: string[] }) =>
      client.post(`/items/complete_transfer`, {
        code: codes[0],
      }),
  },
  shipments: {
    getStorage: ({ page, filter }: StorageDto): Promise<StorageResponseDto[]> =>
      client.get("/shipments", { params: { page, ...filter } }),
    getTransferring: (): Promise<StorageResponseDto[]> =>
      client.get("/shipments/transferring"),
    getShipment: (id: number) => client.get(`/shipments/${id}`),
    updateShipment: (data: any) => client.patch("/shipments/received", data),
    updateComment: (id: number, data: ShipmentsUpdateCommentDto) =>
      client.patch(`/shipments/${id}`, data),
    createCustomsDeclaration: ({
      itemId,
      data,
    }: CreateShipmentsCustomDeclarationDto) =>
      client.post(`/shipments/${itemId}/customs_declarations`, {
        customs_declarations: data,
      }),
    getShops: (): Promise<ShopsResponseDto[]> => client.get("/shop_emblems"),
    getExpectedShops: (): Promise<ShopsResponseDto[]> =>
      client.get("/expected_shipments/shop_emblems"),
    updateAddons: (
      id: number,
      data: UpdateAddonsDto,
      inventoryId: number | null,
    ) => {
      if (inventoryId) {
        return client.patch(`/items/${inventoryId}/update_addons`, {
          addons: data.addon_ids,
        });
      }
      return client.patch(`/shipments/${id}/update_addons`, data);
    },
    importCustomsDeclaration: ({ id, data }: ImportCustomsDeclarationDto) =>
      client.post(
        `/shipments/${id}/customs_declarations/import_declaration`,
        data,
      ),
  },
  cart: {
    getCart: (): Promise<CartResponseDto> => client.get("/current_parcel"),
    deleteCart: (): Promise<DeleteCartResponseDto> =>
      client.delete("/current_parcel"),
    addParcels: (data: AddParcelsDto) =>
      client.post("current_parcel_shipments", data),
    addInventoryParcelItems: (data: AddInventoryParcelItemsDto) =>
      client.post("current_parcel_items", data),
    setAddress: (data: SetAddressDto) =>
      client.post("/current_parcel/destination_addresses/select", data),
    setDeliveryMethod: (data: SetDeliveryMethodDto) =>
      client.patch("/current_parcel/update_preferred_carrier", data),
    setPackingMethods: (data: SetPackingMethodDto) =>
      client.post("/current_parcel/optional_line_items", data),
    setAddonsMethods: (data: SetAddonsMethodDto) =>
      client.patch("/current_parcel_items/update_items_addons", data),
    setConsolidationAddonsMethods: (data: SetConsolidationAddonsMethodsDto) =>
      client.post("/current_parcel/addons", data),
    getAllDeliveryMethods: (): Promise<DeliveryShippingMethodsResponseDto[]> =>
      client.get("/shipping_methods"),
    getDeliveryMethods: (): Promise<DeliveryShippingMethodsResponseDto[]> =>
      client.get("/current_parcel/shipping_methods"),
    getPackingMethods: (): Promise<PackingMethodsResponseDto[]> =>
      client.get("/current_parcel/optional_line_items"),
    getAddonsMethods: (): Promise<AddonsMethodsResponseDto[]> =>
      client.get("/addons"),
    deleteAddonsMethods: () =>
      client.delete("/current_parcel_items/delete_all_items_addons"),
    getConsolidationAddonsMethods: (): Promise<
      ConsolidationAddonsMethodsResponseDto[]
    > => client.get("/consolidation_addons"),
    // getConsolidationAddonsMethods: () => client.get('/current_parcel/addons'), // to fetch what we saved
    importCustomInformation: () =>
      client.post("/current_parcel/import_customs_informations"),
    createCustomsDeclaration: (data: CustomInformationDto) =>
      client.post("/current_parcel/customs_informations", {
        customs_informations: data,
      }),
    importCustomsDeclaration: ({ id, data }: ImportCustomsDeclarationDto) =>
      client.post(
        `/consolidations/${id}/customs_informations/import_declaration`,
        data,
      ),
    consolidateCart: () => client.post("/current_parcel/consolidate"),
    setPartnerId: (data: SetPartnerIdDto): Promise<SetPartnerIdResponseDto> =>
      client.patch(`/current_parcel/set_post_most_id`, data),
    getDescriptions: () => client.get("/hs_codes"),
  },
  outgoing: {
    getOutgoing: ({
      type,
      params,
    }: OutgoingDto): Promise<OutgoingResponseDto> =>
      client.get(`/outgoing/consolidations/${type}`, { params }),
    getDetailedItem: (id: number): Promise<OutgoingResponseDto> =>
      client.get(`/consolidations/${id}`),
    getCustomsDeclarations: (id: number) =>
      client.get(`/consolidations/${id}/customs_informations`),
    createCustomsDeclaration: ({
      itemId,
      data,
    }: {
      itemId: number;
      data: CustomInformationDto;
    }) =>
      client.post(`/consolidations/${itemId}/customs_informations`, {
        customs_informations: data,
      }),
    updateDeliveryMethod: (id: number, data: SetDeliveryMethodDto) =>
      client.patch(`/consolidations/${id}/update_preferred_carrier`, data),
    updateAddress: (id: number, data: any) =>
      client.patch(`/consolidations/${id}/update_address`, data),
    cancelConsolidation: (id: number) =>
      client.post(`/consolidations/${id}/cancel`),
    updateRequireInsurance: (id: number, data: any) =>
      client.patch(`/consolidations/${id}/update_require_insurance`, data),
    getDeliveryMethods: (id: number) =>
      client.get(`/consolidations/${id}/shipping_methods`),
    updateConsolidationAddons: (id: number, data: SetPackingMethodDto) =>
      client.post(`/consolidations/${id}/addons`, data),
    updateConsolidationOptionalLineItems: (
      id: number,
      data: SetPackingMethodDto,
    ) => client.post(`/consolidations/${id}/optional_line_items`, data),
    removeItemFromConsolidation: (consolidationId: number, id: number) =>
      client.delete(`/consolidations/${consolidationId}/items/${id}`),
  },
  purchase: {
    prepaid: (id: number) => client.post(`/consolidations/${id}/prepaid`),
    checkout: (id: number, data: any) =>
      client.post(`/purchase/consolidations/${id}/paypals/checkout`, data),
    confirmation: (id: number, data: any) =>
      client.post(`/purchase/consolidations/${id}/confirmations`, data),
    complete: (id: number, data: PurchaseConsolidationPaypalsCompleteDto) =>
      client.post(`/purchase/consolidations/${id}/paypals/complete`, data),
    checkoutCreditCard: (id: number) =>
      client.get(`purchase/consolidations/${id}/credit_cards/checkout`),
    completeCreditCard: (
      id: number,
      data: PurchaseConsolidationCreditCardCompleteDto,
    ) =>
      client.post(`purchase/consolidations/${id}/credit_cards/complete`, data),
  },
  purchase_all: {
    confirmation: (): Promise<PurchaseAllConformationsResponseDto> =>
      client.post(`purchase_all/confirmations`),
    checkout_paypal: (data: any) =>
      client.post(`/purchase_all/paypals/checkout`, data),
    complete_paypal: (data: any) =>
      client.post(`/purchase_all/paypals/complete`, data),
  },
  history: {
    getHistory: ({ year }: HistoryParamsDto): Promise<HistoryResponseDto> =>
      client.get("/history/orders", { params: { year } }),
    getHistoryDetailed: ({
      week_number,
      shipping_method,
      year,
      page,
    }: HistoryDetailedParamsDto) =>
      client.get(`/history/orders/week`, {
        params: { week_number, shipping_method, year, page },
      }),
    getHistoryFullDetailedItem: (
      id: number,
    ): Promise<HistoryFullDetailedItemResponseDto> =>
      client.get(`/consolidations/${id}`),
  },
  books: {
    getBooksTransactionsHistory: ({
      page,
      filter,
    }: BooksTransactionsHistoryParamsDto) =>
      client.get("/account_transactions", { params: { page, ...filter } }),
    getBooksRecipients: ({ searchInAddress }: { searchInAddress: string }) =>
      client.get("/destination_addresses", { params: { searchInAddress } }),
  },
  items: {
    putAway: ({ id, data }: ItemsPutAwayDto) =>
      client.patch(`/items/${id}/put_away`, data),
    updateItemComment: (id: number, data: ItemsReceivedItemDto) =>
      client.patch(`/items/${id}/received_item`, data),
    getInventoryItems: ({ page, filter }: any) =>
      client.get("/items", { params: { page, ...filter } }),
  },
  expected: {
    getExpectedItems: ({ page, filter }: ExpectedShipmentsDto) =>
      client.get("/expected_shipments", { params: { page, ...filter } }),
    createExpectedItem: ({ expected_shipment }: CreateExpectedShipmentDto) =>
      client.post(`/expected_shipments`, expected_shipment),
    updateExpectedItem: ({
      expected_shipment,
      id,
    }: UpdateExpectedShipmentDto) =>
      client.patch(`/expected_shipments/${id}`, expected_shipment),
    deleteExpectedItem: (id: number) =>
      client.delete(`/expected_shipments/${id}`),
    getCustomsDeclarationsForExpectedItem: (
      id: number,
    ): Promise<CustomsDeclarationsForExpectedItemResponseDto[]> =>
      client.get(`/expected_shipments/${id}/customs_declarations`),
    createCustomsDeclarationForExpectedItem: ({
      itemId,
      data,
    }: CreateCustomsDeclarationsForExpectedItemDto) =>
      client.post(`/expected_shipments/${itemId}/customs_declarations`, {
        customs_declarations: data,
      }),
  },
  recharge: {
    rechargeBalance: ({ data }: RechargeCreditCardDto) =>
      client.post(`/recharge/credit_cards/complete`, data),
    rechargePayPalCheckout: (data: RechargePayPalCheckoutDto) =>
      client.post(`/recharge/paypal/checkout`, data),
    rechargePayPalComplete: (data: RechargePayPalCompleteDto) =>
      client.post(`/recharge/paypal/complete`, data),
    getBooksVoucherHistory: () => client.get(`/client_vouchers`),
    createBooksVoucher: (data: BooksCreateBalanceVoucherFormValues) =>
      client.post(`/client_vouchers`, {
        client_voucher: {
          value: data.value,
        },
      }),
    redeemBooksVoucher: (data: BooksRedeemVoucherFormValues) =>
      client.post(`/client_vouchers/redeem`, data),
    getCryptoDepositsHistory: () => client.get(`/deposits`),
    getCryptoWallets: () => client.get(`/deposits/wallets`),
    createCryptoDeposit: (data: BooksCreateCryptoDepositFormValues) =>
      client.post(`/deposits`, {
        deposit: {
          sender_wallet: data.sender_wallet,
          network: "TRC",
        },
      }),
    updateCryptoDeposit: (data: BooksUpdateCryptoDepositFormValues) =>
      client.patch(`/deposits/${data.id}`, {
        deposit: {
          ...data,
          network: "TRC",
        },
      }),
  },
};

export default API;
