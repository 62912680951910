export const SHIPMENT_TRANSFERRING_STATE = "shipment_transferring";

export const ITEM_TRANSFERRING_STATE = "item_transferring";

export const isTransferringState = (state: string) =>
  state === SHIPMENT_TRANSFERRING_STATE || state === ITEM_TRANSFERRING_STATE;

export const capitalized = (word: string) => {
  if (word) return word.charAt(0).toUpperCase() + word.slice(1);
  return word;
};

export function openExternalURL(url: string) {
  // For mobile platform it is not posible to call window.open from async call,
  // browser allows to open new window only by clicking UI component
  // more to read: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
  const isMobile = /Mobi/.test(navigator.userAgent);
  if (isMobile) {
    window.location.assign(url);
  } else {
    window.open(url, "_blank");
  }
}
