import { Map } from 'immutable';
import { initialState } from '../reducers/auth';
import { peek, peekOr, selector } from '../selectorHelpers';

// AUTH SELECTORS
export const authSelect = (state) => state.get('auth', initialState);

export const authSelectUser = selector(authSelect, peekOr(['user'], Map()));
export const authSelectAuthenticated = selector(
  authSelect,
  peek('authenticated'),
);
export const authSelectFfid = selector(
  [authSelectAuthenticated, authSelectUser],
  (isAuthenticated, user) => (isAuthenticated ? `SB${user.get('ffid')}` : null),
);
export const authSelectEmail = selector(authSelectUser, peek('email'));
export const authSelectCreatedAt = selector(authSelectUser, peek('created_at'));
export const authSelectFistName = selector(authSelectUser, peek('first_name'));
export const authSelectLastName = selector(authSelectUser, peek('last_name'));
export const authSelectFullName = selector(
  [authSelectAuthenticated, authSelectFistName, authSelectLastName],
  (isAuthenticated, firstName, lastName) =>
    isAuthenticated ? `${firstName} ${lastName}` : null,
);
export const authSelectLocale = selector(authSelect, peek('user', 'locale'));
export const authSelectUnits = selector(
  authSelect,
  peek('user', 'display_weight_in'),
);
export const authSelectAllowSearchByLocation = selector(
  authSelect,
  peek('user', 'extended_tools'),
);
export const authSelectAllowExtendedTools = selector(
  authSelect,
  peek('user', 'extended_tools'),
);

export const storageIsImpersonateState = selector(
  authSelect,
  peek('impersonated'),
);

const authFeePercentRoot = selector(authSelect, peek('user', 'fee_percent'));

export const authCreditCardFeePercent = selector(
  authFeePercentRoot,
  peek('credit'),
);

export const authPaypalFeePercent = selector(
  authFeePercentRoot,
  peek('paypal'),
);

export const authSelectLoading = selector(authSelect, peek('isLoading'));

// ROUTING SELECTORS
export const selectRouter = (state) => state.get('router');

export const routeSelectPathname = selector(
  selectRouter,
  peek('location', 'pathname'),
);
export const routeSelectState = selector(
  selectRouter,
  peekOr(['location', 'state'], Map()),
);
