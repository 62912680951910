import React, { ChangeEvent, FC } from "react";
import Input from "components/Input/Input";
import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { BooksRedeemVoucherFormProps } from "types/books";
import { ButtonColor, ButtonVariant } from "../../../../enums";
import { getProfile } from "../../../../reduxFolder/reducers/auth";
import { booksRedeemVoucher } from "../../../../reduxFolder/reducers/books";
import redeemVoucherFormik from "../../helpers/redeemVoucherFormik";
import {
  AddFundsContainer,
  AddFundsFormRow,
  BooksVoucherCreateFormWrapper,
  PayButton,
} from "../../styles/BooksAddFunds.style";

const BooksRedeemVoucherForm: FC<BooksRedeemVoucherFormProps> = React.memo(
  ({ booksRedeemVoucher, getProfile }) => {
    const { t } = useTranslation("common");

    const {
      handleBlur,
      handleSubmit,
      handleChange,
      values,
      errors: { code: amountError },
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
    } = useFormik(redeemVoucherFormik(t, booksRedeemVoucher, getProfile));

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue("code", event.target.value);
    };

    return (
      <BooksVoucherCreateFormWrapper>
        <AddFundsContainer fluid>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                name="value"
                value={values.code}
                onChange={handleCodeChange}
                invalid={touched.code && !!amountError}
                placeholder={"Enter voucher code"}
                tooltip={amountError}
              />
            </Col>
          </AddFundsFormRow>
        </AddFundsContainer>
        <PayButton
          onClick={() => handleSubmit()}
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
          variant={ButtonVariant.Filled}
          color={ButtonColor.Secondary}
        >
          {t("books.voucherFundsPanel.titleRedeem")}
        </PayButton>
      </BooksVoucherCreateFormWrapper>
    );
  },
);

const withConnect = connect(createStructuredSelector({}), {
  booksRedeemVoucher,
  getProfile,
});

export default withConnect(BooksRedeemVoucherForm);
