import { call, put, takeLatest } from 'redux-saga/effects';
// requests
import api from '../../utils/api';
import { openExternalURL } from '../../utils/common';
// utils
import { toastResponseError } from '../../utils/responseMessageHelper';
// const
import {
  BOOKS_CHECKOUT_PAYPAL,
  catchBooksTransactionsError,
  defaultParams,
  fetchBooksTransactions,
  GET_BOOKS_TRANSACTIONS_HISTORY,
  updateBooksTransactionsHistory,
} from '../reducers/books';
import { clearPurchase } from '../reducers/purchase';

/* worker-saga */
function* onGetBooksTransactionsHistory({ payload }) {
  try {
    const params = payload.size ? payload : defaultParams;
    yield put(fetchBooksTransactions(params));
    const response = yield call(
      api.books.getBooksTransactionsHistory,
      payload.toJS(),
    );
    yield put(updateBooksTransactionsHistory(response.set('params', params)));
  } catch (response) {
    toastResponseError(response);
    catchBooksTransactionsError(response);
  }
}

/* books recharge sagas */
function* onError(error) {
  toastResponseError(error);
}

function* onBooksCheckoutPayPal({ payload }) {
  const { amount } = payload;

  try {
    const origin = window.location.origin;
    const checkoutResponse = yield call(api.recharge.rechargePayPalCheckout, {
      amount: amount,
      success_url: `${origin}/books/add-funds/success`,
      cancel_url: `${origin}/books/add-funds/cancel`,
    });

    // redirect to paypal
    const redirect = checkoutResponse.getIn(['data', 'redirect_url']);
    openExternalURL(redirect);
  } catch (error) {
    yield onError(error);
  }
}

/* watcher-saga */
function* watchBooksActions() {
  yield takeLatest(
    GET_BOOKS_TRANSACTIONS_HISTORY,
    onGetBooksTransactionsHistory,
  );
  yield takeLatest(BOOKS_CHECKOUT_PAYPAL, onBooksCheckoutPayPal);
}

export default watchBooksActions;
