import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import {
  BooksCreateCryptoDepositFormValues,
  BooksUpdateCryptoDepositFormValues,
} from "types/books";
import * as yup from "yup";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../utils/responseMessageHelper";

const createCryptoDepositFormik = (
  t: TFunction,
  depositItemId: number | null,
  booksCreateCryptoDeposit: any,
  setDepositItem: any,
  booksUpdateCryptoDeposit: any,
) => {
  // Edit
  if (depositItemId) {
    return {
      initialValues: {
        sender_wallet: "",
        amount: "",
        tx_id: "",
      },
      validationSchema: yup.object({
        sender_wallet: yup.string().required(t("error.requiredField")),
        amount: yup.string().required(t("error.requiredField")),
        tx_id: yup.string().required(t("error.requiredField")),
      }),
      onSubmit: (
        values: BooksUpdateCryptoDepositFormValues,
        {
          setSubmitting,
          resetForm,
        }: FormikHelpers<BooksUpdateCryptoDepositFormValues>,
      ) => {
        setSubmitting(true);

        booksUpdateCryptoDeposit({
          ...values,
          id: depositItemId,
        })
          .then((resp: any) => {
            resetForm();
            setDepositItem(null);
            toastResponseSuccess(
              t("books.crypto.successfulUpdateTransactions", {
                amount: values.amount,
              }),
            );
            setSubmitting(false);
          })
          .catch((error: Error) => {
            toastResponseError(error);
            setSubmitting(false);
          })
          .finally(() => setSubmitting(false));
      },
    };
  }

  // Create
  return {
    initialValues: {
      sender_wallet: "",
      amount: "",
      tx_id: "",
    },

    validationSchema: yup.object({
      sender_wallet: yup.string().required(t("error.requiredField")),
    }),
    onSubmit: (
      values: BooksCreateCryptoDepositFormValues,
      { setSubmitting }: FormikHelpers<BooksCreateCryptoDepositFormValues>,
    ) => {
      setSubmitting(true);

      booksCreateCryptoDeposit(values)
        .then((resp: any) => {
          if (resp && resp.value && resp.value.get("data")) {
            setDepositItem(resp.value.get("data").toJS());
          }

          toastResponseSuccess(t("books.crypto.successfulCreateTransactions"));
          setSubmitting(false);
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
  };
};

export default createCryptoDepositFormik;
