import React, { FC, useState } from "react";
import Input from "components/Input/Input";
import { useFormik } from "formik";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  authSelectLocale,
  authSelectUser,
} from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import {
  BooksCryptoCreateFormProps,
  CryptoDepositHistoryItem,
} from "types/books";
import { ButtonColor, ButtonVariant } from "../../../../enums";
import { getProfile } from "../../../../reduxFolder/reducers/auth";
import {
  booksCreateCryptoDeposit,
  booksUpdateCryptoDeposit,
} from "../../../../reduxFolder/reducers/books";
import createCryptoDepositFormik from "../../helpers/createCryptoDepositFormik";
import {
  AddFundsContainer,
  AddFundsFormRow,
  BooksVoucherCreateFormWrapper,
  PayButton,
} from "../../styles/BooksAddFunds.style";
import trc20Logo from "./../../images/trc20-logo.png";
import {
  WalletAddress,
  WalletAddressCtn,
  WalletInfoWrapper,
} from "./BooksCryptoFunds.styles";

const BooksCryptoCreateForm: FC<BooksCryptoCreateFormProps> = React.memo(
  ({ depositWallets, booksCreateCryptoDeposit, booksUpdateCryptoDeposit }) => {
    const { t } = useTranslation("common");
    const [depositItem, setDepositItem] =
      useState<null | CryptoDepositHistoryItem>(null);

    const isEdit = depositItem !== null;
    const depositItemId = isEdit ? depositItem.id : null;

    const {
      handleSubmit,
      handleChange,
      values,
      errors,
      touched,
      isSubmitting,
      isValid,
    } = useFormik(
      // @ts-ignore
      createCryptoDepositFormik(
        t,
        depositItemId,
        booksCreateCryptoDeposit,
        setDepositItem,
        booksUpdateCryptoDeposit,
      ),
    );

    const renderWallet = () => (
      <AddFundsFormRow>
        <WalletInfoWrapper>
          <img src={trc20Logo} style={{ height: "24px" }} />
          <WalletAddressCtn>
            <div>{t("books.crypto.trc20wallet")}</div>
            <WalletAddress>{depositWallets.get("wallet_trc")}</WalletAddress>
          </WalletAddressCtn>
        </WalletInfoWrapper>
      </AddFundsFormRow>
    );

    return (
      <BooksVoucherCreateFormWrapper>
        <AddFundsContainer fluid>
          {renderWallet()}
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                name="sender_wallet"
                value={values.sender_wallet}
                onChange={handleChange}
                invalid={touched.sender_wallet && !!errors.sender_wallet}
                placeholder={"Enter sender's wallet"}
                tooltip={errors.sender_wallet}
                disabled={isEdit}
              />
            </Col>
          </AddFundsFormRow>
          {isEdit && (
            <>
              <AddFundsFormRow>
                <Col xs={12}>
                  <Input
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    invalid={touched.amount && !!errors.amount}
                    placeholder={"Transaction amount"}
                    tooltip={errors.amount}
                    type="number"
                  />
                </Col>
              </AddFundsFormRow>
              <AddFundsFormRow>
                <Col xs={12}>
                  <Input
                    name="tx_id"
                    value={values.tx_id}
                    onChange={handleChange}
                    invalid={touched.tx_id && !!errors.tx_id}
                    placeholder={"Transaction id"}
                    tooltip={errors.tx_id}
                  />
                </Col>
              </AddFundsFormRow>
            </>
          )}
        </AddFundsContainer>
        <PayButton
          onClick={() => handleSubmit()}
          isLoading={isSubmitting}
          disabled={!isValid || isSubmitting}
          variant={ButtonVariant.Filled}
          color={ButtonColor.Secondary}
        >
          {isEdit ? t("books.crypto.update") : t("books.crypto.create")}
        </PayButton>
      </BooksVoucherCreateFormWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    user: authSelectUser,
    currentLocale: authSelectLocale,
  }),
  {
    booksCreateCryptoDeposit,
    booksUpdateCryptoDeposit,
    getProfile,
  },
);

export default withConnect(BooksCryptoCreateForm);
