// utils
import { List, Map } from 'immutable';
import { call, put, select, takeLeading } from 'redux-saga/effects';
// api
import api from '../../utils/api';
import { openExternalURL } from '../../utils/common';
import { toastResponseError } from '../../utils/responseMessageHelper';
import {
  getOutgoing,
  movePackageFromWaitingPaymentToPaid,
} from '../reducers/outgoing';
import {
  CHECKOUT,
  CHECKOUT_CREDIT_CARD,
  CHECKOUT_PAYPAL,
  clearPurchase,
  COMPLETE_ALL_PAYPAL,
  COMPLETE_PAYPAL,
  CONFIRMATION_ALL,
} from '../reducers/purchase';
import { closeRightPanel } from '../reducers/rightPanel';
import {
  outgoingSelectPackingData,
  outgoingSelectPaymentData,
} from '../selectors/outgoingSelectors';

function* onError(error) {
  yield put(clearPurchase());
  toastResponseError(error);
}

function* onConfirmationSuccess(id) {
  yield put(closeRightPanel());
  yield put(movePackageFromWaitingPaymentToPaid(id));
  yield put(clearPurchase());
}

/* worker-saga */
function* onCheckout({ payload }) {
  const { id } = payload;

  try {
    // confirmation
    yield call(api.purchase.confirmation, id);
    yield onConfirmationSuccess(id);
  } catch (error) {
    yield onError(error);
  }
}

function* onCheckoutPayPal({ payload }) {
  const { id } = payload;

  try {
    const origin = window.location.origin;
    const checkoutResponse = yield call(api.purchase.checkout, id, {
      success_url: `${origin}/parcels/outgoing/${id}/success`,
      cancel_url: `${origin}/parcels/outgoing/${id}/cancel`,
    });

    // redirect to paypal
    const redirect = checkoutResponse.getIn(['data', 'redirect_url']);
    openExternalURL(redirect);

    yield put(clearPurchase());
  } catch (error) {
    yield onError(error);
  }
}

function* onCheckoutCreditCard({ payload }) {
  const { id, data } = payload;

  try {
    yield call(api.purchase.checkoutCreditCard, id);
    yield call(api.purchase.completeCreditCard, id, data);
    yield call(api.purchase.confirmation, id);
    yield onConfirmationSuccess(id);
  } catch (error) {
    yield onError(error);
  }
}

function* onPaymentCompleteViaPayPal({ payload }) {
  const { id, query } = payload;

  try {
    // complete
    yield call(api.purchase.complete, id, {
      PayerID: query.PayerID,
      token: query.token,
    });

    // confirmation
    yield call(api.purchase.confirmation, id);
    try {
      yield onConfirmationSuccess(id);
    } catch (e) {
      const paymentData = yield select(outgoingSelectPaymentData);

      yield put(
        getOutgoing(
          Map({
            type: 'waiting_for_payment',
            params: {
              page: 1,
              per_page: paymentData.get('items', List()).size + 1,
            },
          }),
        ),
      );
      yield put(
        getOutgoing(
          Map({
            type: 'paid',
            params: {
              page: 1,
              per_page: 0,
            },
          }),
        ),
      );
    }
  } catch (error) {
    yield onError(error);
  }
}

/* purchase all sagas */
function* onConfirmationAllSuccess() {
  const paymentData = yield select(outgoingSelectPaymentData);
  const inProgressData = yield select(outgoingSelectPackingData);

  yield put(
    getOutgoing(
      Map({
        type: 'waiting_for_payment',
        params: {
          page: 1,
          per_page: paymentData.get('items', List()).size + 1,
        },
      }),
    ),
  );
  yield put(
    getOutgoing(
      Map({
        type: 'paid',
        params: {
          page: 1,
          per_page: 0,
        },
      }),
    ),
  );
  yield put(closeRightPanel());
  yield put(
    getOutgoing(
      Map({
        type: 'packing_in_progress',
        params: {
          page: 1,
          per_page: inProgressData.get('items', List()).size,
        },
      }),
    ),
  );
  yield put(clearPurchase());
}

function* onPaymentConfirmAll() {
  try {
    yield call(api.purchase_all.confirmation);
    yield onConfirmationAllSuccess();
  } catch (error) {
    // check if it's confirmation error to checkout
    if (!error.data && !error.data.pay_url && !error.data.sign_id) {
      throw toastResponseError(error);
    }

    const sign_id = error.data.sign_id;
    yield onCheckoutPayPalAll({ sign_id });
  }
}

function* onCheckoutPayPalAll(payload) {
  const { sign_id } = payload;

  try {
    const origin = window.location.origin;
    const checkoutResponse = yield call(api.purchase_all.checkout_paypal, {
      sign_id: sign_id,
      success_url: `${origin}/parcels/outgoing/all/success?sign_id=${sign_id}`,
      cancel_url: `${origin}/parcels/outgoing/all/cancel?sign_id=${sign_id}`,
    });

    // redirect to paypal
    const redirect = checkoutResponse.getIn(['data', 'redirect_url']);
    openExternalURL(redirect);
  } catch (error) {
    yield onError(error);
  }
}

function* onPaymentCompleteAllViaPaypal({ payload }) {
  const { query } = payload;

  try {
    // complete
    yield call(api.purchase_all.complete_paypal, {
      sign_id: query.sign_id,
      PayerID: query.PayerID,
      token: query.token,
    });

    // confirmation
    yield call(api.purchase_all.confirmation);
    yield onConfirmationAllSuccess();
  } catch (error) {
    yield onError(error);
  }
}

/* watcher-saga */
function* watchPurchaseActions() {
  yield takeLeading(CHECKOUT, onCheckout);
  yield takeLeading(COMPLETE_PAYPAL, onPaymentCompleteViaPayPal);
  yield takeLeading(CHECKOUT_PAYPAL, onCheckoutPayPal);
  yield takeLeading(CHECKOUT_CREDIT_CARD, onCheckoutCreditCard);
  yield takeLeading(CONFIRMATION_ALL, onPaymentConfirmAll);
  yield takeLeading(COMPLETE_ALL_PAYPAL, onPaymentCompleteAllViaPaypal);
}

export default watchPurchaseActions;
