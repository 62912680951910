import React, { FC, useState } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import { getBooksCryptoWallets } from "../../../../reduxFolder/reducers/books";
import { booksSelectDepositWallets } from "../../../../reduxFolder/selectors/booksSelectors";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import BooksCryptoCreateForm from "./BooksCryptoCreateForm";
import {
  Container,
  ContentContainer,
  FormWrapper,
} from "./BooksCryptoFunds.styles";
import BooksCryptoHistory from "./BooksCryptoHistory";

interface BooksCryptoFundsPanelProps {
  depositWallets: any;
  getBooksCryptoWallets: () => Promise<void>;
}

const BooksCryptoFundsPanel: FC<BooksCryptoFundsPanelProps> = React.memo(
  ({ depositWallets, getBooksCryptoWallets }) => {
    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState<string>(
      t("books.cryptoFundsPanel.titleCreate"),
    );

    useFetchOnceEffect(true, getBooksCryptoWallets);

    return (
      <ContentWrapper>
        <Heading>{t("books.cryptoFundsPanel.panelTitle")}</Heading>
        <TabsView openTab={activeTab} onTabChange={setActiveTab}>
          <Tab title={t("books.cryptoFundsPanel.titleCreate")}>
            <ContentContainer>
              <Container>
                <FormWrapper>
                  <BooksCryptoCreateForm depositWallets={depositWallets} />
                </FormWrapper>
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("books.cryptoFundsPanel.titleLog")}>
            {activeTab === t("books.cryptoFundsPanel.titleLog") && (
              <ContentContainer>
                <Container>
                  <BooksCryptoHistory />
                </Container>
              </ContentContainer>
            )}
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    depositWallets: booksSelectDepositWallets,
  }),
  {
    getBooksCryptoWallets,
  },
);

export default withConnect(BooksCryptoFundsPanel);
