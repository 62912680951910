import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { BooksCreateBalanceVoucherFormValues } from "types/books";
import * as yup from "yup";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../utils/responseMessageHelper";

const createBalanceVoucherFormik = (
  t: TFunction,
  booksCreateVoucher: any,
  redirect: (resp: any) => void,
) => {
  return {
    initialValues: {
      value: "",
    },

    validationSchema: yup.object({
      value: yup.string().required(t("error.requiredField")),
    }),
    onSubmit: (
      values: BooksCreateBalanceVoucherFormValues,
      {
        setSubmitting,
        resetForm,
      }: FormikHelpers<BooksCreateBalanceVoucherFormValues>,
    ) => {
      const { value } = values;
      const amountValue = value !== null ? +value : 0;

      setSubmitting(true);

      booksCreateVoucher({ value: amountValue })
        .then((resp: any) => {
          resetForm();
          toastResponseSuccess(
            t("books.voucher.successfulTransactions", { amount: value }),
          );
          setSubmitting(false);
          redirect(resp);
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
  };
};

export default createBalanceVoucherFormik;
