import { useEffect } from "react";
import { List, Map } from "immutable";
import { Col, Container, Row } from "react-grid-system";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import useFetchOnceEffect from "../../../../hooks/useFetchOnceEffect";
import { getBooksVoucherHistory } from "../../../../reduxFolder/reducers/books";
import { voucherHistoryItems } from "../../../../reduxFolder/selectors/booksSelectors";
import { capitalized } from "../../../../utils/common";
import {
  HistoryItemFirstCol,
  HistoryItemRow,
} from "../../styles/BooksAddFunds.style";

export type BooksVoucherHistoryProps = {
  voucherHistoryItems: List<any>;
  getBooksVoucherHistory: () => void;
};

export const BooksVoucherHistory = ({
  voucherHistoryItems,
  getBooksVoucherHistory,
}: BooksVoucherHistoryProps) => {
  useEffect(() => {
    getBooksVoucherHistory();
  }, []);

  return (
    <Container>
      {voucherHistoryItems.map((item) => (
        <HistoryItemRow>
          <HistoryItemFirstCol>{item.get("code")}</HistoryItemFirstCol>
          <Col>{capitalized(item.get("state"))}</Col>
          <Col>{item.get("value")}</Col>
        </HistoryItemRow>
      ))}
    </Container>
  );
};

const withConnect = connect(
  createStructuredSelector({
    voucherHistoryItems,
  }),
  {
    getBooksVoucherHistory,
  },
);

export default withConnect(BooksVoucherHistory);
