import React from "react";
import { BadgeCtn } from "./Badge.style";

export type BadgeProps = {
  children: React.ReactNode;
  $color?: "crimson";
};

export const Badge = ({ children, $color }: BadgeProps) => {
  return <BadgeCtn $color={$color}>{children}</BadgeCtn>;
};
