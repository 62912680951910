import React, { FC } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { redirect } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { RechargeCreditCardDto } from "types/api/recharge";
import { ButtonColor, ButtonVariant } from "../../../../enums";
import {
  createRechargeBalance,
  updateAmountFunds,
} from "../../../../reduxFolder/reducers/books";
import { authCreditCardFeePercent } from "../../../../reduxFolder/selectors/globalSelectors";
import formatter from "../../../../utils/formatter";
import addFundsFormik from "../../helpers/addFundsFormik";
import {
  ContentContainer,
  FormWrapper,
  PayButton,
} from "../../styles/BooksAddFunds.style";
import BooksAddFundsForm from "./BooksAddFundsForm";

interface BooksAddFundsPanelProps {
  createRechargeBalance: ({ data }: RechargeCreditCardDto) => Promise<void>;
  updateAmountFunds: (amount: string) => void;
  authCreditCardFeePercent: string;
}

const BooksAddFundsPanel: FC<BooksAddFundsPanelProps> = React.memo(
  ({ createRechargeBalance, updateAmountFunds, authCreditCardFeePercent }) => {
    const { t } = useTranslation("common");
    const redirectFunc = () => redirect("/parcels");

    const {
      handleBlur,
      handleSubmit,
      handleChange,
      errors,
      values,
      touched,
      isSubmitting,
      isValid,
      setFieldValue,
    } = useFormik(
      addFundsFormik(t, createRechargeBalance, redirectFunc, updateAmountFunds),
    );

    const { amount } = values;
    const amountFloat = parseFloat(amount);
    const commissionFeeFloat = parseFloat(authCreditCardFeePercent);
    const commissionFeeValue =
      amountFloat > 0
        ? formatter.roundTo2Decimal(commissionFeeFloat * amountFloat)
        : 0;

    const payBtnTitle = `${t("common.processBankCard")} ($${amountFloat + commissionFeeValue})`;

    return (
      <ContentWrapper>
        <Heading>{t("books.addFundsPanel.chargeBankCard")}</Heading>
        <ContentContainer>
          <FormWrapper>
            <BooksAddFundsForm
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              createRechargeBalance={createRechargeBalance}
            />
          </FormWrapper>
          <PayButton
            onClick={() => handleSubmit()}
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
            variant={ButtonVariant.Filled}
            color={ButtonColor.Secondary}
          >
            {payBtnTitle}
          </PayButton>
        </ContentContainer>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    authCreditCardFeePercent,
  }),
  {
    createRechargeBalance,
    updateAmountFunds,
  },
);

export default withConnect(BooksAddFundsPanel);
