import { FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { BooksAddPayPalFundsFormValues } from "types/books";
import * as yup from "yup";
import {
  toastResponseError,
  toastResponseSuccess,
} from "../../../utils/responseMessageHelper";

const addPayPalFundsFormik = (
  t: TFunction,
  booksCheckoutPayPal: any,
  redirect: () => Response,
) => {
  return {
    initialValues: {
      amount: "",
    },

    validationSchema: yup.object({
      amount: yup.string().required(t("error.requiredField")),
    }),
    onSubmit: (
      values: BooksAddPayPalFundsFormValues,
      {
        setSubmitting,
        resetForm,
      }: FormikHelpers<BooksAddPayPalFundsFormValues>,
    ) => {
      const { amount } = values;
      const amountValue = amount !== null ? +amount : 0;

      setSubmitting(true);

      booksCheckoutPayPal({ amount: amountValue })
        .then(() => {
          resetForm();
          toastResponseSuccess(
            t("books.paypal.successfulTransactions", { amount }),
          );
          setSubmitting(false);
          redirect();
        })
        .catch((error: Error) => {
          toastResponseError(error);
          setSubmitting(false);
        })
        .finally(() => setSubmitting(false));
    },
  };
};

export default addPayPalFundsFormik;
