import React, { FC, useState } from "react";
import {
  ContentWrapper,
  Heading,
} from "containers/RightPanel/RightPanel.styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RechargeCreditCardDto } from "types/api/recharge";
import {
  createRechargeBalance,
  updateAmountFunds,
} from "../../../../reduxFolder/reducers/books";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import BooksRedeemVoucherForm from "./BooksRedeemVoucherForm";
import BooksVoucherCreateForm from "./BooksVoucherCreateForm";
import {
  Container,
  ContentContainer,
  FormWrapper,
} from "./BooksVoucherFunds.styles";
import BooksVoucherHistory from "./BooksVoucherHistory";

interface BooksVoucherFundsPanelProps {
  createRechargeBalance: ({ data }: RechargeCreditCardDto) => Promise<void>;
  updateAmountFunds: (amount: string) => void;
}

const BooksVoucherFundsPanel: FC<BooksVoucherFundsPanelProps> = React.memo(
  ({ createRechargeBalance, updateAmountFunds }) => {
    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState<string>(
      t("books.voucherFundsPanel.titleCreate"),
    );

    return (
      <ContentWrapper>
        <Heading>{t("books.voucherFundsPanel.panelTitle")}</Heading>
        <TabsView openTab={activeTab} onTabChange={setActiveTab}>
          <Tab title={t("books.voucherFundsPanel.titleCreate")}>
            <ContentContainer>
              <Container>
                <FormWrapper>
                  <BooksVoucherCreateForm />
                </FormWrapper>
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("books.voucherFundsPanel.titleRedeem")}>
            <ContentContainer>
              <Container>
                <FormWrapper>
                  <BooksRedeemVoucherForm />
                </FormWrapper>
              </Container>
            </ContentContainer>
          </Tab>
          <Tab title={t("books.voucherFundsPanel.titleLog")}>
            {activeTab === t("books.voucherFundsPanel.titleLog") && (
              <ContentContainer>
                <Container>
                  <BooksVoucherHistory />
                </Container>
              </ContentContainer>
            )}
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(null, {
  createRechargeBalance,
  updateAmountFunds,
});

export default withConnect(BooksVoucherFundsPanel);
