import React, { ChangeEvent, FC } from "react";
import Input from "components/Input/Input";
import { Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  authPaypalFeePercent,
  authSelectLocale,
} from "reduxFolder/selectors/globalSelectors";
import { createStructuredSelector } from "reselect";
import { BooksAddPayPalFundsFormProps } from "types/books";
import { toastResponseError } from "utils/responseMessageHelper";
import formatter from "../../../../utils/formatter";
import {
  AddFundsContainer,
  AddFundsFormRow,
  BooksAddFundsFormWrapper,
  SummaryCurrency,
  SummaryPriceItem,
  SummaryPriceWrapper,
} from "../../styles/BooksAddFunds.style";

//constants for the range of funds value
const MIN_AMOUNT_ADD_FUNDS = 0.01;
const MAX_AMOUNT_ADD_FUNDS = 10000.0;

const BooksAddPayPalFundsForm: FC<BooksAddPayPalFundsFormProps> = React.memo(
  ({
    values,
    handleChange,
    errors: { amount: amountError },
    touched: touchedProp,
    handleBlur,
    setFieldValue,
    authPaypalFeePercent,
  }) => {
    const { t } = useTranslation("common");

    const touched = {
      address: {},
      cardDetails: {},
      ...touchedProp,
    };

    const amountFloat = parseFloat(values.amount);
    const commissionFeeFloat = parseFloat(authPaypalFeePercent);
    const commissionFeeValue = formatter.roundTo2Decimal(
      commissionFeeFloat * amountFloat,
    );
    // Label in format 5% to show
    const commissionFeeValueLabel = commissionFeeFloat * 100;

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value.replace(/[^0-9.]/g, "");
      let numericValue = parseFloat(inputValue);
      setFieldValue("amount", inputValue);

      //range of funds value
      if (
        numericValue > MAX_AMOUNT_ADD_FUNDS ||
        numericValue < MIN_AMOUNT_ADD_FUNDS
      ) {
        toastResponseError(
          t("books.errorAmountRangeAddFunds", {
            min: MIN_AMOUNT_ADD_FUNDS,
            max: MAX_AMOUNT_ADD_FUNDS,
          }),
          "error-range-funds",
        );
      }
    };

    const renderPriceItem = (title: string, amount: number) => {
      return (
        <SummaryPriceItem>
          {title}
          <SummaryPriceWrapper>
            <SummaryCurrency>{"$"}</SummaryCurrency>
            {Math.abs(amount)}
          </SummaryPriceWrapper>
        </SummaryPriceItem>
      );
    };

    return (
      <BooksAddFundsFormWrapper>
        <AddFundsContainer fluid>
          <AddFundsFormRow>
            <Col xs={12}>
              <Input
                name="amount"
                value={values.amount}
                onChange={handleAmountChange}
                invalid={touched.amount && !!amountError}
                placeholder={"$ 0.00"}
                tooltip={amountError}
                type="number"
              />
              {amountFloat > 0 &&
                renderPriceItem(
                  t("parcels.commissionFeeLabel", {
                    fee: commissionFeeValueLabel,
                  }),
                  commissionFeeValue,
                )}
            </Col>
          </AddFundsFormRow>
        </AddFundsContainer>
      </BooksAddFundsFormWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    currentLocale: authSelectLocale,
    authPaypalFeePercent,
  }),
);

export default withConnect(BooksAddPayPalFundsForm);
