// utils
import { fromJS, List } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import api from '../../utils/api';

export const defaultParams = fromJS({
  page: 1,
  filter: null,
});

export const initialState = fromJS({
  isLoading: false,
  isLoadingAddFunds: false,
  totalAmount: 0,
  hasItems: false,
  historyItems: [],
  params: defaultParams,
  selectedRecipientId: null,
  amountFunds: '',
  voucherHistory: new List(),
  depositWallets: {},
  depositsHistory: [],
});

// const
export const GET_BOOKS_TRANSACTIONS_HISTORY =
  'books/GET_BOOKS_TRANSACTIONS_HISTORY';
export const GET_BOOKS_RECIPIENTS = 'books/GET_BOOKS_RECIPIENTS';
export const FETCH_BOOKS_TRANSACTIONS_HISTORY =
  'books/FETCH_BOOKS_TRANSACTIONS_HISTORY';
export const CATCH_BOOKS_TRANSACTIONS_ERROR =
  'books/CATCH_BOOKS_TRANSACTIONS_ERROR';
export const UPDATE_BOOKS_TRANSACTIONS_HISTORY =
  'books/UPDATE_BOOKS_TRANSACTIONS_HISTORY';
export const SET_SELECTED_RECIPIENT_ID = 'books/SET_SELECTED_RECIPIENT_ID';
export const CREATE_RECHARGE_BALANCE = 'books/CREATE_RECHARGE_BALANCE';
export const SET_AMOUNT_FUNDS = 'books/SET_AMOUNT_FUNDS';

// Recharge
export const BOOKS_CHECKOUT_PAYPAL = 'books/BOOKS_CHECKOUT_PAYPAL';

// Balance vouchers
export const GET_BOOKS_VOUCHER_HISTORY = 'books/GET_BOOKS_VOUCHER_HISTORY';
export const BOOKS_CREATE_VOUCHER = 'books/BOOKS_CREATE_VOUCHER';
export const BOOKS_REDEEM_VOUCHER = 'books/BOOKS_REDEEM_VOUCHER';

// Crypto
export const GET_BOOKS_CRYPTO_WALLETS = 'books/GET_BOOKS_CRYPTO_WALLETS';
export const GET_BOOKS_CRYPTO_DEPOSITS_HISTORY =
  'books/GET_BOOKS_CRYPTO_DEPOSITS_HISTORY';
export const CREATE_CRYPTO_DEPOSIT = 'books/CREATE_CRYPTO_DEPOSIT';
export const UPDATE_CRYPTO_DEPOSIT = 'books/UPDATE_CRYPTO_DEPOSIT';

// action creators
export const getBooksTransactionsHistory = createAction(
  GET_BOOKS_TRANSACTIONS_HISTORY,
  (data = defaultParams) => data,
);
export const fetchBooksTransactions = createAction(
  FETCH_BOOKS_TRANSACTIONS_HISTORY,
);
export const catchBooksTransactionsError = createAction(
  CATCH_BOOKS_TRANSACTIONS_ERROR,
);
export const updateBooksTransactionsHistory = createAction(
  UPDATE_BOOKS_TRANSACTIONS_HISTORY,
);
export const setSelectedRecipientId = createAction(SET_SELECTED_RECIPIENT_ID);
export const createRechargeBalance = createAction(
  CREATE_RECHARGE_BALANCE,
  ({ data }) => ({
    promise: api.recharge.rechargeBalance({ data }),
  }),
);
export const updateAmountFunds = createAction(SET_AMOUNT_FUNDS);

// Recharge
export const booksCheckoutPayPal = createAction(BOOKS_CHECKOUT_PAYPAL);

// Balance vouchers
export const getBooksVoucherHistory = createAction(
  GET_BOOKS_VOUCHER_HISTORY,
  () => ({
    promise: api.recharge.getBooksVoucherHistory(),
  }),
);
export const booksCreateVoucher = createAction(
  BOOKS_CREATE_VOUCHER,
  (data) => ({
    promise: api.recharge.createBooksVoucher(data),
  }),
);

export const booksRedeemVoucher = createAction(
  BOOKS_REDEEM_VOUCHER,
  (data) => ({
    promise: api.recharge.redeemBooksVoucher(data),
  }),
);

// Crypto
export const getBooksCryptoWallets = createAction(
  GET_BOOKS_CRYPTO_WALLETS,
  () => ({
    promise: api.recharge.getCryptoWallets(),
  }),
);

export const getBooksCryptoDepositsHistory = createAction(
  GET_BOOKS_CRYPTO_DEPOSITS_HISTORY,
  () => ({
    promise: api.recharge.getCryptoDepositsHistory(),
  }),
);

export const booksCreateCryptoDeposit = createAction(
  CREATE_CRYPTO_DEPOSIT,
  (data) => ({
    promise: api.recharge.createCryptoDeposit(data),
  }),
);

export const booksUpdateCryptoDeposit = createAction(
  UPDATE_CRYPTO_DEPOSIT,
  (data) => ({
    promise: api.recharge.updateCryptoDeposit(data),
  }),
);

// helpers
export const normalizeItems = (items) =>
  items.map((item) => {
    return item;
  });

const booksReducer = handleActions(
  {
    [GET_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) =>
      payload.size ? state : state.set('historyItems', null),

    [FETCH_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) => {
      return state.set('isLoading', true);
    },

    [UPDATE_BOOKS_TRANSACTIONS_HISTORY]: (state, { payload }) => {
      const isPagination =
        state.getIn(['params', 'page']) < payload.getIn(['params', 'page']);
      const headers = payload.get('headers');
      const totalAmountHeader = headers.find((x) => x.get(0) === 'total');
      const totalAmountFromHeader = totalAmountHeader
        ? parseInt(totalAmountHeader.get(1))
        : state.get('totalAmount');

      const normalizedItems = normalizeItems(payload.get('data'));
      const hasItems = !!normalizedItems.size;

      return state
        .update('historyItems', (items) =>
          isPagination ? items.concat(normalizedItems) : normalizedItems,
        )
        .set('totalAmount', totalAmountFromHeader)
        .set('hasItems', hasItems)
        .set('params', payload.get('params'))
        .set('isLoading', false);
    },

    [CATCH_BOOKS_TRANSACTIONS_ERROR]: (state) => state.set('isLoading', false),

    [SET_SELECTED_RECIPIENT_ID]: (state, { payload }) =>
      state.set('selectedRecipientId', payload),

    [SET_AMOUNT_FUNDS]: (state, { payload }) =>
      state.set('amountFunds', payload),

    [`${GET_BOOKS_VOUCHER_HISTORY}_SUCCESS`]: (state, { payload }) => {
      return state
        .set('voucherHistory', payload.get('data'))
        .set('isLoading', false);
    },

    [`${GET_BOOKS_CRYPTO_WALLETS}_SUCCESS`]: (state, { payload }) => {
      return state
        .set('depositWallets', payload.get('data'))
        .set('isLoading', false);
    },

    [`${GET_BOOKS_CRYPTO_DEPOSITS_HISTORY}_SUCCESS`]: (state, { payload }) => {
      return state
        .set('depositsHistory', payload.get('data'))
        .set('isLoading', false);
    },
  },

  initialState,
);

export default booksReducer;
