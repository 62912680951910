import { useEffect } from "react";
import { List } from "immutable";
import { Container } from "react-grid-system";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Badge } from "../../../../components/Badge/Badge";
import { getBooksCryptoDepositsHistory } from "../../../../reduxFolder/reducers/books";
import { booksSelectDepositsHistory } from "../../../../reduxFolder/selectors/booksSelectors";
import { capitalized } from "../../../../utils/common";
import { CryptoItemRow } from "../../styles/BooksAddFunds.style";
import {
  CryptoItem,
  CryptoItemAddressItem,
  CryptoItemAddressItemTitle,
  CryptoItemAddressList,
  CryptoItemAmount,
  CryptoItemAmountWrapper,
  CryptoItemHeader,
} from "./BooksCryptoFunds.styles";

export type BooksVoucherHistoryProps = {
  cryptoHistoryItems: List<any>;
  getBooksCryptoDepositsHistory: () => void;
};

export const BooksVoucherHistory = ({
  cryptoHistoryItems,
  getBooksCryptoDepositsHistory,
}: BooksVoucherHistoryProps) => {
  useEffect(() => {
    getBooksCryptoDepositsHistory();
  }, []);

  return (
    <Container>
      {cryptoHistoryItems.map((item) => (
        <>
          <CryptoItemRow>
            <CryptoItem>
              <CryptoItemHeader>
                <Badge $color="crimson">{item.get("network")}</Badge>
                <Badge>{capitalized(item.get("state"))}</Badge>
              </CryptoItemHeader>
              {item.get("amount") && (
                <CryptoItemAmountWrapper>
                  <CryptoItemAddressItemTitle>
                    Amount:
                  </CryptoItemAddressItemTitle>
                  <CryptoItemAmount>{item.get("amount")}</CryptoItemAmount>
                </CryptoItemAmountWrapper>
              )}
              <CryptoItemAddressList>
                <CryptoItemAddressItem>
                  <CryptoItemAddressItemTitle>
                    Address
                  </CryptoItemAddressItemTitle>
                  <code>{item.get("sender_wallet")}</code>
                </CryptoItemAddressItem>
                {item.get("tx_id") && (
                  <CryptoItemAddressItem>
                    <CryptoItemAddressItemTitle>
                      Transaction ID
                    </CryptoItemAddressItemTitle>
                    <code>{item.get("tx_id")}</code>
                  </CryptoItemAddressItem>
                )}
              </CryptoItemAddressList>
            </CryptoItem>
          </CryptoItemRow>
        </>
      ))}
    </Container>
  );
};

const withConnect = connect(
  createStructuredSelector({
    cryptoHistoryItems: booksSelectDepositsHistory,
  }),
  {
    getBooksCryptoDepositsHistory,
  },
);

export default withConnect(BooksVoucherHistory);
