import PreLoader from "components/PreLoader/PreLoader";
import { Content } from "containers/RightPanel/RightPanel.styles";
import { fadeIn } from "react-animations";
import styled, { css, keyframes } from "styled-components";

export const fadeInAnimation = css`
  animation: 0.5s ${keyframes`${fadeIn}`} !important;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled(Content)`
  ${fadeInAnimation}

  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
  }

  & > div > button {
    margin: auto auto 0 auto;
  }
`;

export const Title = styled.span`
  margin-left: 10px;
`;

export const Spinner = styled(PreLoader)`
  position: absolute;
  margin-top: 80px;
`;

export const FormWrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  align-self: center;
  padding: 15px 0;

  button {
    margin: 5px;
  }
`;

export const WalletInfoWrapper = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  padding: 0 15px;
`;

export const WalletAddressCtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const WalletAddress = styled.div`
  font-weight: bold;
`;

export const CryptoItem = styled.div`
  padding: 8px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.lightgrey};
  border-radius: 8px;
  width: 100%;
`;

export const CryptoItemHeader = styled.div`
  display: flex;
  gap: 8px;
`;

export const CryptoItemAmountWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const CryptoItemAmount = styled.h4`
  margin: 0;
`;

export const CryptoItemAddressList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CryptoItemAddressItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CryptoItemAddressItemTitle = styled.div`
  color: #71717a;
`;
