import React, { FC } from "react";
import voucherIcon from "components/Icon/icons/credit-card.svg";
import payPalIcon from "components/Icon/icons/paypal.svg";
import {
  PaymentFundsCol,
  PaymentFundsRow,
  PaymentImgIcon,
} from "containers/Books/styles/BooksAddFunds.style";
import { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { updateAmountFunds } from "reduxFolder/reducers/books";
import { openRightPanel } from "reduxFolder/reducers/rightPanel";
import { amountFunds } from "reduxFolder/selectors/booksSelectors";
import { createStructuredSelector } from "reselect";
import { OpenRightPanel } from "types/common/rightPanel";
import { panelSelectType } from "../../../../reduxFolder/selectors/rightPanelSelectors";
import masterCardLogo from "./../../images/mastercardLogo.png";
import tetherUSDTLogo from "./../../images/TetherUSDTLogo.png";
import visaLogo from "./../../images/visaLogo.png";

interface BooksAddFundsBlockProps {
  isPanelOpened: boolean;
  isBlockPanel?: boolean;
  openRightPanel: OpenRightPanel;
  updateAmountFunds: (amount: string) => void;
  panelType: string;
}

const BooksFundsPanelType = {
  CARD_FUNDS: "BOOKS_ADD_FUNDS",
  PAYPAL_FUNDS: "BOOKS_ADD_PAYPAL_FUNDS",
  VOUCHER_FUNDS: "BOOKS_VOUCHER_FUNDS",
  CRYPTO_FUNDS: "BOOKS_CRYPTO_FUNDS",
};

const BooksAddFundsBlock: FC<BooksAddFundsBlockProps> = React.memo(
  ({ openRightPanel, panelType }) => {
    const { t } = useTranslation("common");

    const showBankCardForm = () => {
      openRightPanel(BooksFundsPanelType.CARD_FUNDS, Map({}));
    };

    const showPayPalForm = () => {
      openRightPanel(BooksFundsPanelType.PAYPAL_FUNDS, Map({}));
    };

    const showCryptoForm = () => {
      openRightPanel(BooksFundsPanelType.CRYPTO_FUNDS, Map({}));
    };

    const showVoucherForm = () => {
      openRightPanel(BooksFundsPanelType.VOUCHER_FUNDS, Map({}));
    };

    return (
      <>
        <PaymentFundsRow
          onClick={showBankCardForm}
          selected={panelType === BooksFundsPanelType.CARD_FUNDS}
        >
          <PaymentFundsCol>
            <PaymentImgIcon>
              <img src={visaLogo} alt="Visa" style={{ height: "20px" }} />
            </PaymentImgIcon>
            {t("books.bankCard.title")}
          </PaymentFundsCol>
        </PaymentFundsRow>
        <PaymentFundsRow
          onClick={showPayPalForm}
          selected={panelType === BooksFundsPanelType.PAYPAL_FUNDS}
        >
          <PaymentFundsCol>
            <PaymentImgIcon>
              <img src={payPalIcon} alt="Visa" style={{ height: "24px" }} />
            </PaymentImgIcon>
            PayPal
          </PaymentFundsCol>
        </PaymentFundsRow>
        <PaymentFundsRow
          onClick={showCryptoForm}
          selected={panelType === BooksFundsPanelType.CRYPTO_FUNDS}
        >
          <PaymentFundsCol>
            <PaymentImgIcon>
              <img src={tetherUSDTLogo} alt="Visa" style={{ height: "24px" }} />
            </PaymentImgIcon>
            USDT TRC-20
          </PaymentFundsCol>
        </PaymentFundsRow>
        <PaymentFundsRow
          onClick={showVoucherForm}
          selected={panelType === BooksFundsPanelType.VOUCHER_FUNDS}
        >
          <PaymentFundsCol>
            <PaymentImgIcon>
              <img src={voucherIcon} alt="Visa" style={{ height: "24px" }} />
            </PaymentImgIcon>
            {t("books.voucher.title")}
          </PaymentFundsCol>
        </PaymentFundsRow>
      </>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    amountFunds,
    panelType: panelSelectType,
  }),
  {
    updateAmountFunds,
    openRightPanel,
  },
);

export default withConnect(BooksAddFundsBlock);
