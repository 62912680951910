import React, { FC, FormEvent, MouseEvent, useCallback, useState } from "react";
import CustomsDeclarationV2 from "components/CustomDeclaration/CustomsDeclarationV2";
import creditCardIcon from "components/Icon/icons/credit-card.svg";
import payPalIcon from "components/Icon/icons/paypal.svg";
import {
  BackButton,
  Content,
  ContentWrapper,
  Heading,
  LeftArrowIcon,
} from "containers/RightPanel/RightPanel.styles";
import { ButtonColor, ButtonVariant } from "enums/Button";
import { IconType } from "enums/Icon";
import { useFormik } from "formik";
import { List, Map } from "immutable";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { CustomInformationDto } from "types/api/cart";
import { OpenRightPanel } from "types/common/rightPanel";
import * as yup from "yup";
import CreditCardForm from "../../../../components/CreditCardForm/CreditCardForm";
import { createCustomsDeclaration } from "../../../../reduxFolder/reducers/outgoing";
import {
  checkout,
  checkoutCreditCard,
  checkoutPayPal,
} from "../../../../reduxFolder/reducers/purchase";
import { openRightPanel } from "../../../../reduxFolder/reducers/rightPanel";
import {
  authCreditCardFeePercent,
  authPaypalFeePercent,
  authSelectUser,
} from "../../../../reduxFolder/selectors/globalSelectors";
import { outgoingSelectDetailedItems } from "../../../../reduxFolder/selectors/outgoingSelectors";
import { purchaseSelectId } from "../../../../reduxFolder/selectors/purchaseSelectors";
import formatter from "../../../../utils/formatter";
import Tab from "../../../RightPanel/components/Tab";
import TabsView from "../../../RightPanel/components/TabsView";
import PriceList from "../PriceList/PriceList";
import {
  CreditCardFormWrapper,
  PayButton,
  PaymentMethodIcon,
  ScrollableContent,
  Section,
  SectionTitle,
  SwitchButton,
  SwitchWrapper,
} from "./ReviewAndPayPanel.styles";

interface ReviewAndPayPanelProps {
  panelData: any;
  detailedItem: any;
  purchaseId: string | number;
  openRightPanel: OpenRightPanel;
  createCustomsDeclaration: (data: CustomInformationDto) => Promise<void>;
  checkoutCreditCard: (data: any) => Promise<void>;
  checkoutPayPal: (data: any) => Promise<void>;
  checkout: (data: any) => Promise<void>;
  user: any;
  authCreditCardFeePercent: string;
  authPaypalFeePercent: string;
}

const ReviewAndPayPanel: FC<ReviewAndPayPanelProps> = React.memo(
  ({
    checkout,
    checkoutPayPal,
    checkoutCreditCard,
    panelData,
    purchaseId,
    detailedItem,
    openRightPanel,
    createCustomsDeclaration,
    user,
    authCreditCardFeePercent,
    authPaypalFeePercent,
  }) => {
    const { t } = useTranslation("common");

    const allowedToUseCreditCard = user.get("credit_card_allowed", false);
    const paymentMethods = ["paypal"];
    if (allowedToUseCreditCard === true) {
      paymentMethods.push("credit_card");
    }
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
      paymentMethods[0],
    );

    const id = panelData.get("detailedItemID");
    const editCustomsData = panelData.get("isEditCustomData", false);
    const historyConsolidation = panelData.get("consolidation");
    const detailed =
      historyConsolidation !== undefined
        ? historyConsolidation
        : detailedItem.get(id, Map());
    const estimate = detailed.get("estimate", Map());
    const declarations = detailed.get("customs_declarations", List());
    const total = estimate.get("total");
    const userTotal = estimate.get("user_total");

    const commissionFeeFloat = parseFloat(
      selectedPaymentMethod === "paypal"
        ? authPaypalFeePercent
        : authCreditCardFeePercent,
    );
    const commissionFeeValue = formatter.roundTo2Decimal(
      commissionFeeFloat * userTotal,
    );

    const shouldPayOnlyFromBalance = userTotal <= 0;
    const toPayTotal = shouldPayOnlyFromBalance
      ? total
      : formatter.roundTo2Decimal(userTotal + commissionFeeValue);

    const state = detailed.get("state");
    const isPaid = state === "paid" || state === "shipped";

    const continuesToSelectedMethod = (method: string) => {
      const paymentMethodTitel =
        method === paymentMethods[0] ? "PayPal" : t("common.creditCard");
      return t("common.continueToPaymentMethod", {
        paymentMethod: paymentMethodTitel,
      });
    };

    const creditCardFormik = useFormik({
      initialValues: {
        name: "",
        number: "",
        expiration: "",
        cvv: "",
      },
      validationSchema: yup.object({
        name: yup.string().required(t("error.requiredField")),
        number: yup.string().required(t("error.requiredField")),
        expiration: yup
          .string()
          .matches(
            /^\d{2}\/\d{4}$/,
            t("error.creditCard.wrongExpirationFormat"),
          )
          .required(t("error.requiredField")),
        cvv: yup
          .string()
          .matches(
            /^\d{3,4}$/,
            t("error.numberLonger", {
              field: t("purchase.creditCard.cvv"),
              length: "3-4",
            }),
          )
          .required(t("error.requiredField")),
      }),
      onSubmit: (values) => {
        const { name, number, expiration, cvv } = values;
        const [month, year] = expiration.split("/");

        const request = {
          credit_card: {
            card_name: name,
            card_number: number,
            exp_month: month,
            exp_year: year,
            card_code: cvv,
          },
        };

        checkoutCreditCard({ id, data: request });
      },
    });

    const handleBack = useCallback(() => {
      openRightPanel(panelData.get("returnPreviousPanel"), panelData);
    }, [openRightPanel, panelData]);

    const handlePayFromBalance = useCallback(() => {
      checkout({ id });
    }, [checkout, id]);

    const handlePayViaPayPal = useCallback(() => {
      checkoutPayPal({ id });
    }, [checkoutPayPal, id]);

    const switchPaymentMethod = useCallback(
      (method: string) => {
        setSelectedPaymentMethod(method);
      },
      [setSelectedPaymentMethod],
    );

    const handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement>) => {
      creditCardFormik.handleSubmit();
    };

    const paymentForm = (method: string) => {
      switch (method) {
        case paymentMethods[0]:
        default:
          return null;
        case paymentMethods[1]:
          return (
            <CreditCardFormWrapper>
              <CreditCardForm
                handleBlur={creditCardFormik.handleBlur}
                handleChange={creditCardFormik.handleChange}
                values={creditCardFormik.values}
                errors={creditCardFormik.errors}
                touched={creditCardFormik.touched}
              />
            </CreditCardFormWrapper>
          );
      }
    };

    const payButton = () => {
      if (shouldPayOnlyFromBalance) {
        return (
          <PayButton
            onClick={handlePayFromBalance}
            isLoading={purchaseId === id}
            variant={ButtonVariant.Filled}
          >
            {`${t("common.continueToBalance")} (${total})`}
          </PayButton>
        );
      } else {
        let title = `${continuesToSelectedMethod(
          selectedPaymentMethod,
        )} (${toPayTotal})`;

        if (selectedPaymentMethod === paymentMethods[0]) {
          return (
            <PayButton
              onClick={handlePayViaPayPal}
              isLoading={purchaseId === id}
              variant={ButtonVariant.Filled}
            >
              {title}
            </PayButton>
          );
        } else {
          return (
            <PayButton
              onClick={handleSubmitButton}
              isLoading={purchaseId === id}
              disabled={!!purchaseId || !creditCardFormik.isValid}
              variant={ButtonVariant.Filled}
            >
              {title}
            </PayButton>
          );
        }
      }
    };

    const creditCardSwitchButton = () => {
      return (
        <SwitchButton
          selected={selectedPaymentMethod === paymentMethods[1]}
          onClick={() => switchPaymentMethod(paymentMethods[1])}
          sectionsCount={paymentMethods.length}
        >
          <PaymentMethodIcon src={creditCardIcon} alt="Credit Card" />{" "}
          {t("common.creditCard")}
        </SwitchButton>
      );
    };

    const paymentMethodsSection = (
      <>
        <Section>
          <SectionTitle>{t("parcels.paymentMethod")}:</SectionTitle>
          <SwitchWrapper>
            <SwitchButton
              selected={selectedPaymentMethod === paymentMethods[0]}
              onClick={() => switchPaymentMethod(paymentMethods[0])}
              sectionsCount={paymentMethods.length}
            >
              <PaymentMethodIcon src={payPalIcon} alt="PayPal" />
              {`PayPal`}
            </SwitchButton>
            {allowedToUseCreditCard === true ? creditCardSwitchButton() : null}
          </SwitchWrapper>
          {paymentForm(selectedPaymentMethod)}
        </Section>
        {payButton()}
      </>
    );

    const paymentOnlyFromBalance = (
      <>
        <Section>
          <SectionTitle>{t("parcels.paymentByBalance")}</SectionTitle>
        </Section>
        {payButton()}
      </>
    );

    return (
      <ContentWrapper>
        <Heading>
          <BackButton color={ButtonColor.Black50} onClick={handleBack}>
            <LeftArrowIcon type={IconType.Arrow} />
            {detailed.get("sku")}:
          </BackButton>
          {isPaid ? t("parcels.paymentPaid") : t("common.reviewAndPay")}
        </Heading>
        <TabsView>
          <Tab title={t("parcels.details")}>
            <ScrollableContent>
              <PriceList
                parcel={detailed}
                selectedPaymentMethod={selectedPaymentMethod}
              />
              {!isPaid &&
                (shouldPayOnlyFromBalance
                  ? paymentOnlyFromBalance
                  : paymentMethodsSection)}
            </ScrollableContent>
          </Tab>
          <Tab title={t("parcels.customsData")}>
            <Content>
              <CustomsDeclarationV2
                itemId={id}
                readOnly={!editCustomsData}
                declarations={declarations}
                create={createCustomsDeclaration}
                noPadding
              />
            </Content>
          </Tab>
        </TabsView>
      </ContentWrapper>
    );
  },
);

const withConnect = connect(
  createStructuredSelector({
    purchaseId: purchaseSelectId,
    detailedItem: outgoingSelectDetailedItems,
    user: authSelectUser,
    authCreditCardFeePercent,
    authPaypalFeePercent,
  }),
  {
    checkout,
    checkoutPayPal,
    checkoutCreditCard,
    openRightPanel,
    createCustomsDeclaration,
  },
);

export default withConnect(ReviewAndPayPanel);
