import styled from "styled-components";

interface BadgeCtnProps {
  $color?: string;
}

export const BadgeCtn = styled.div<BadgeCtnProps>`
  display: flex;
  align-items: baseline;
  border: 1px solid ${(props) => props.theme.lightgrey};
  background-color: ${(props) => props.theme.lightgrey};
  border-radius: 16px;
  padding: 2px 6px;

  ${({ $color }) =>
    $color === "crimson" &&
    `
      background-color: #fff7ed;
      color: #c2410c;
      border-color: #fed7aa;
    `};
`;
