import { Content } from "containers/RightPanel/RightPanel.styles";
import { fadeIn } from "react-animations";
import { Col, Container, Row } from "react-grid-system";
import styled, { keyframes } from "styled-components";
import { PayButton as Button } from "../../../styles/outgoingStyles";
import { Currency } from "../../../styles/parcelsStyles";

interface AddFundsBlockProps {
  $isPanelOpened: boolean;
  $isBlockPanel?: boolean;
}

export const AddFundsBlock = styled.div<AddFundsBlockProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  button {
    text-wrap: nowrap;
  }

  img {
    width: 60px;
    height: 40px;
  }

  ${({ $isBlockPanel }) =>
    $isBlockPanel &&
    `
    flex-wrap: nowrap;

    input {
      max-width: 170px;
    }`}

  ${({ theme, $isPanelOpened }) =>
    $isPanelOpened &&
    theme.range(
      "padding-right",
      "370px",
      "410px",
      `${theme.breakpoints.lg}px`,
      `${theme.breakpoints.xl}px`,
    )}
`;

export const PayButton = styled(Button)`
  margin-top: auto;
  align-self: center;
`;

export const ContentContainer = styled(Content)`
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  padding-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    padding-top: 10px;
  }
`;

export const AddFundsFormRow = styled(Row)`
  margin-bottom: 15px;
`;

export const BooksAddFundsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BooksVoucherCreateFormWrapper = styled(BooksAddFundsFormWrapper)`
  height: 100%;
`;

interface ParcelRowProps {
  checked?: boolean;
  selected?: boolean;
}

export const PaymentFundsRow = styled(Row)<ParcelRowProps>`
  cursor: pointer;
  ${({ theme }) => theme.range("padding", "10px", "16px")};
  white-space: nowrap;
  animation: 0.3s ${keyframes`${fadeIn}`};

  &.row {
    margin: 0;
  }

  min-height: 68px;

  &:not(:first-child) {
    border-top: none;
  }

  ${({ theme, checked, selected }) => `
    border-top: 1px solid ${theme.lightgrey};
    border-bottom: 1px solid ${theme.lightgrey};
    background-color: ${
      (checked && "rgba(62, 130, 247, 0.1)") ||
      (selected && "rgba(228, 234, 245, 0.3)")
    };

    &:hover {
      background-color: ${checked || "rgba(228, 234, 245, 0.3)"};
    }
  `};

  ${(props) => props.theme.sm`
    padding: 15px 0 15px 0px;
    font-size: 13px;
  `}

  ${(props) => props.theme.xs`
    font-size: 12px;
    padding: 15px 0 5px 0;
  `};
`;

export const PaymentFundsCol = styled(Col)`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const PaymentImgIcon = styled.div`
  min-width: 30px;
  text-align: center;
`;

export const AddFundsContainer = styled(Container)`
  width: 100%;
`;

export const HistoryItemRow = styled(Row)`
  animation: 0.3s ${keyframes`${fadeIn}`};
  padding: 10px 0;

  ${({ theme }) => `
    border-bottom: 1px solid ${theme.lightgrey};
  };
  `};
`;

export const CryptoItemRow = styled(Row)`
  animation: 0.3s ${keyframes`${fadeIn}`};
  padding: 4px 0;
`;

export const CryptoItemRowLast = styled(CryptoItemRow)`
  padding-bottom: 10px;
  margin-bottom: 6px;

  ${({ theme }) => `
    border-bottom: 1px solid ${theme.lightgrey};
  };
  `};
`;

export const HistoryItemFirstCol = styled(Col)`
  flex: 2 !important;
`;

export const VoucherResponseCodeWrapper = styled.div`
  padding: 30px 0;
  margin: 0 15px;
  background: rgba(62, 130, 247, 0.1);
  text-align: center;
  width: 100%;
`;

export const VoucherResponseCodeText = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const Balance = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  background-color: rgba(225, 226, 230, 0.25);
  font-size: 18px;
  font-weight: 700;
  padding: 30px 0;
  margin: 0 15px;
  text-align: center;
  color: ${(props) => props.theme.black50};

  ${(props) => props.theme.md`
    justify-content: center;
    order: -1;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  `}
`;

export const BalanceText = styled.span`
  font-size: 24px;
  color: ${(props) => props.theme.black};
  margin-left: 4px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 4px;
  }

  i {
    margin-left: 4px;
  }
`;

export const PriceItem = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;
  white-space: nowrap;
  margin-bottom: 10px;

  &:last-child {
    font-size: 16px;
    margin-top: 10px;

    div {
      font-weight: 700;
    }
  }
`;

export const SummaryPriceItem = styled(PriceItem)`
  justify-content: flex-end;
`;

export const SummaryPriceWrapper = styled(PriceWrapper)`
  min-width: 50px;
  margin-left: 8px;
  justify-content: flex-end;
`;

export const SummaryCurrency = styled(Currency)`
  margin-right: 0 !important;
`;
